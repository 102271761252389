import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\nBASE_URL = "/";\nIS_LOGGEDIN = "0";\nSITE_URL = BASE_URL;\nLANG_BASE_URL = "/";\n'
          }}
        />

        <script
          async
          type="text/javascript"
          src="/static/js/generated_js/958a58972bf272cd5ead1fe8b4a225e1.js?=1588071944"
        />
        <script async typ="text/javascript" src="/static/js/generated_js/jquery-migrate-1.4.1-min.js" />
        <div id="layout">
          <div className="head">
            <div className="inner">
              <h1 className="logoHolder">
                <a className="logo" href="/">
                  <img src="/static/i/layout/New_Logo.png" alt="VISINE" />
                </a>
              </h1>
              <div className="menuHolder">
                <ul id="menu">
                  <li className="mainMenu">
                    <a className="active" href="/">
                      Főoldal
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/voros-szemre/">Vörös szemre</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/termekeink/visine-classic/">
                      VISINE<sup>®</sup> Rapid
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/szemtorna/">Szemtorna</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/kapcsolat/">Kapcsolat</a>
                  </li>
                </ul>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div id="content" className="content">
            <div className="index" id="indexPage">
              <div className="contentInner">
                <div className="headerImage">
                  <img
                    src="/static/i/layout/VISINE-banner-new.jpg"
                    alt="VISINE - Hogy a szemeid tükrözzék, ki vagy!"
                    className="onlyDesktop"
                  />
                  <img
                    src="/static/i/layout/header_eye_index_mobile.jpg"
                    alt="VISINE - Hogy a szemeid tükrözzék, ki vagy!"
                    className="onlyMobile"
                  />
                </div>
                <div className="symptomsSelector">
                  <div className="redEyeBlock symptomBlock">
                    <div className="imageHolder" />
                    <div className="textHolder">
                      <div className="symptomsText">
                        Vörös, irritált szemek?
                      </div>
                      <a href="/voros-szemre/" className="btn red">
                        Tudjon meg többet!
                      </a>
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
              <div className="bottomTestBlock products">
                <div className="contentInner">
                  <h3>
                    Válassza a Visine<sup>®</sup> RAPID-ot
                  </h3>
                  <div className="productsHolder">
                    <div className="product">
                      <a
                        className="productImage"
                        href="/termekeink/visine-classic/"
                      >
                        <img
                          src="/static/i/products/Visine-Rapid-left-78x145.png"
                          alt="VISINE Rapid 0,5 mg/ml oldatos szemcsepp"
                        />
                      </a>
                      <div className="productName">
                        <a href="/termekeink/visine-classic/">
                          VISINE<sup>®</sup> Rapid <br />
                          0,5 mg/ml oldatos szemcsepp
                        </a>
                      </div>
                    </div>
                    <div className="clear" />
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="footer">
            <div className="inner">
              <div className="productStatus">
                <div className="contentInner">
                  <div className="footerInner">
                    VISINE<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp. Vény
                    nélkül kapható, tetrizolin-hidroklorid hatóanyagú gyógyszer.
                  </div>
                </div>
              </div>
              <div className="footerMenu">
                <ul>
                  <li>
                    <a href="/jogi-nyilatkozat/">Jogi nyilatkozat</a>
                  </li>
                  <li>
                    <a href="/adatvedelmi-szabalyzat/">
                      Adatvédelmi szabályzat
                    </a>
                  </li>
                  <li>
                    <a href="/oldalterkep/">Oldaltérkép</a>
                  </li>
                  <li>
                    <a href="/cookie-szabalyzat/">Cookie szabályzat</a>
                  </li>
                  <li>
                    <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</a>
                  </li>
                </ul>
              </div>
              <div className="johnson">
                <div className="contentInner">
                  <div className="footerInner">
                    <p className="bigger">©JNTL CONSUMER HEALTH (HUNGARY) KFT., 2023. 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em.</p>
                    <p>
                      Ezt az oldalt a JNTL Consumer Health (Hungary) Kft. tette közzé, és
                      felel annak tartalmáért.
                    </p>
                    <p>
                      Ez az oldal magyarországi felhasználók számára készült.
                    </p>
                    <p>Utoljára frissítve: 2023.02.01.</p>
                    <p>HU-VI-2200008</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="risks">
            <div className="contentInner">
              <div className="footerInner">
                A kockázatokról és a mellékhatásokról olvassa el a
                betegtájékoztatót, vagy kérdezze meg kezelőorvosát,
                gyógyszerészét!
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Page;
